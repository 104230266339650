import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { FeatureFlags, FeatureFlagVariants } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { PaymentMethod } from '@app/membership/settings/__generated__/payment-methods-graphql.service.types';
import { CreateStripeSetupIntentGraphQL } from '@app/membership/settings/create-stripe-setup-intent-graphql.service';
import { DetachStripePaymentMethodGraphQL } from '@app/membership/settings/detach-stripe-payment-method-graphql.service';
import { PaymentMethodsGraphQL } from '@app/membership/settings/payment-methods-graphql.service';
import { UpdateDefaultCopayStripeIdGraphQL } from '@app/membership/settings/update-default-copay-stripe-id-graphql.service';
import { UpdatePreferredCopayStripeIdGraphQL } from '@app/membership/settings/update-preferred-copay-stripe-id-graphql.service';
import { UpdateStripePaymentMethodGraphQL } from '@app/membership/settings/update-stripe-payment-method-graphql.service';
import { StripeCreditCardComponent } from '@app/shared/stripe-credit-card/stripe-credit-card.component';

export interface PaymentMethodData {
  id: string;
  last4: string;
  expMonth: number;
  expYear: number;
  brand: string | null;
  cardNickname: string | null;
  isDefaultCopay: boolean;
  isPreferredCopay: boolean;
  isExpired: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PaymentCaptureService {
  constructor(
    private readonly launchDarklyService: LaunchDarklyService,
    private readonly paymentMethodsGraphQL: PaymentMethodsGraphQL,
    private readonly createStripeSetupIntentGraphQL: CreateStripeSetupIntentGraphQL,
    private readonly updateStripePaymentMethodGraphQL: UpdateStripePaymentMethodGraphQL,
    private readonly updateDefaultCopayStripeIdGraphQL: UpdateDefaultCopayStripeIdGraphQL,
    private readonly updatePreferredCopayStripeIdGraphQL: UpdatePreferredCopayStripeIdGraphQL,
    private readonly detachStripePaymentMethodGraphQL: DetachStripePaymentMethodGraphQL,
  ) {}

  getPaymentCaptureFlag$(): Observable<FeatureFlagVariants> {
    return this.launchDarklyService.featureFlag$<FeatureFlagVariants>(
      FeatureFlags.PAYMENT_CAPTURE_MILESTONE_1,
      FeatureFlagVariants.OFF,
    );
  }

  getPaymentCaptureFlagEnabled$(): Observable<boolean> {
    return this.getPaymentCaptureFlag$().pipe(
      map(result => result === FeatureFlagVariants.ON_T1 || result === FeatureFlagVariants.ON_T2),
    );
  }

  getPaymentCaptureFlagOptional$(): Observable<boolean> {
    return this.getPaymentCaptureFlag$().pipe(map(result => result === FeatureFlagVariants.ON_T2));
  }

  getPaymentCaptureFlagM2$(): Observable<FeatureFlagVariants> {
    return this.launchDarklyService.featureFlag$<FeatureFlagVariants>(
      FeatureFlags.PAYMENT_CAPTURE_MILESTONE_2,
      FeatureFlagVariants.OFF,
    );
  }

  getPaymentCaptureFlagM2Enabled$(): Observable<boolean> {
    return this.getPaymentCaptureFlagM2$().pipe(
      map(result => result === FeatureFlagVariants.ON_T1 || result === FeatureFlagVariants.ON_T2),
    );
  }

  getPaymentCaptureFlagM2Optional$(): Observable<boolean> {
    return this.getPaymentCaptureFlag$().pipe(map(result => result === FeatureFlagVariants.ON_T2));
  }

  getPaymentCaptureDevFlag$(): Observable<boolean> {
    return this.launchDarklyService.featureFlag$<boolean>(FeatureFlags.PAYMENT_CAPTURE_WEB_DEV_MODE, false);
  }

  getPaymentMethods$(): Observable<PaymentMethodData[]> {
    return combineLatest([
      this.paymentMethodsGraphQL.watch({}, { fetchPolicy: 'network-only' }).valueChanges,
      this.getPaymentCaptureFlagM2Enabled$(),
      this.getPaymentCaptureDevFlag$(),
    ]).pipe(
      map(([response, flagM2Enabled, devFlagEnabled]) => {
        let paymentMethods = response.data?.patient?.paymentMethods ?? [];

        // Exclude membership cards if not milestone 2
        paymentMethods = flagM2Enabled
          ? paymentMethods
          : paymentMethods.filter(method => method?.id?.startsWith('pm_'));

        return paymentMethods.map((method: PaymentMethod) => ({
          id: method.id,
          last4: method.last4,
          expMonth: method.expMonth,
          expYear: method.expYear,
          brand: method.brand,
          cardNickname: method.cardNickname,
          isDefaultCopay: method.isDefaultCopay,
          isPreferredCopay: method.isPreferredCopay,
          isExpired:
            // If dev flag enabled, treat cards named "expired" as expired
            (devFlagEnabled && method?.cardNickname?.toLowerCase() === 'expired') ||
            this.isCardExpired(method.expYear, method.expMonth),
        }));
      }),
    );
  }

  hasPaymentMethods$(): Observable<boolean> {
    return this.getPaymentMethods$().pipe(map(methods => methods.length > 0));
  }

  createStripeSetupIntent$(): Observable<string> {
    return this.createStripeSetupIntentGraphQL.mutate().pipe(
      take(1),
      map(response => response.data?.createStripeSetupIntentForPatient?.setupIntentClientSecret ?? ''),
    );
  }

  addPaymentMethod$(stripeComponent: StripeCreditCardComponent, setupIntentClientSecret: string): Observable<string> {
    return stripeComponent
      .confirmSetupIntent(setupIntentClientSecret)
      .pipe(map(response => response.payment_method ?? ''));
  }

  setPaymentMethodNickname$(stripePaymentMethodId: string, nickname: string): Observable<string> {
    return this.updateStripePaymentMethodGraphQL
      .mutate({ stripePaymentMethodId, nickname })
      .pipe(map(() => stripePaymentMethodId));
  }

  setDefaultCopayMethod$(stripePaymentMethodId: string): Observable<string> {
    return this.updateDefaultCopayStripeIdGraphQL
      .mutate({ stripePaymentMethodId })
      .pipe(map(() => stripePaymentMethodId));
  }

  setPreferredCopayMethod$(stripePaymentMethodId: string): Observable<string> {
    return this.updatePreferredCopayStripeIdGraphQL
      .mutate({ stripePaymentMethodId })
      .pipe(map(() => stripePaymentMethodId));
  }

  deletePaymentMethod$(stripePaymentMethodId: string): Observable<string> {
    return this.detachStripePaymentMethodGraphQL
      .mutate({ stripePaymentMethodId })
      .pipe(map(() => stripePaymentMethodId));
  }

  isCardExpired(expYear: number, expMonth: number): boolean {
    const expDate = new Date(expYear, expMonth, 0); // last day of expiration month
    const today = new Date();
    return expDate < today;
  }
}
