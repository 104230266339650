import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

import {
  PatientUpdatePreferredCopayStripeId,
  PatientUpdatePreferredCopayStripeIdVariables,
} from './__generated__/update-preferred-copay-stripe-id-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class UpdatePreferredCopayStripeIdGraphQL extends Mutation<
  PatientUpdatePreferredCopayStripeId,
  PatientUpdatePreferredCopayStripeIdVariables
> {
  document = gql`
    mutation PatientUpdatePreferredCopayStripeId($stripePaymentMethodId: String!) {
      updatePreferredCopayStripeIdForPatient(input: { stripePaymentMethodId: $stripePaymentMethodId }) {
        success
        mutationErrors {
          messages
          path
        }
      }
    }
  `;
}
